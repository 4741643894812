<template>
  <div class="page messages">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="panel unique-scrollbar">
            <k-messages-table v-model="selectedMessages"></k-messages-table>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <k-button variant="primary"
              @click="UjUzenet"
              size="sm"
              icon="fa-plus"
              class="add-user"></k-button>
  </div>
</template>

<script>
export default {
  name: 'messages-page',
  data() {
    return {
      selectedMessages: [],
    };
  },
  mounted() {},
  created() {},
  methods: {
    UjUzenet() {
      this.$router.push({ name: 'Messaging' });
    },
  },
  computed: {},
  watch: {},
};
</script>
